import React, { useEffect, useRef } from 'react';

const TradingViewWidget = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    if (widgetRef.current) {
      try {
        widgetRef.current.innerHTML = ''; // Clear previous content

        // Create a script element
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
          width: "100%",
          height: "600",
          currencies: [
            "EUR",
            "USD",
            "JPY",
            "GBP",
            "CHF",
            "AUD",
            "CAD",
            "NZD"
          ],
          isTransparent: false,
          colorTheme: "light",
          locale: "en",
          backgroundColor: "#ffffff"
        });

        // Append the script to the widget container
        widgetRef.current.appendChild(script);
      } catch (error) {
        console.error("Error loading TradingView widget:", error);
      }
    }

    // Cleanup function to remove the widget script
    return () => {
      if (widgetRef.current) {
        widgetRef.current.innerHTML = ''; // Clear the widget container
      }
    };
  }, []);

  return (
    <div className="table" style={{ paddingTop: '20px' }}>
      <div className="tradingview-widget-container" ref={widgetRef}>
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
            <span className="blue-text">Track all markets on TradingView</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TradingViewWidget;
