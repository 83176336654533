import React, { useState } from "react";

import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

export default function OfferDetails() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/home");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const [server, setServer] = React.useState("Vantage International Live 11");
  const [mtAccount, setMtAccount] = React.useState("");
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setServer(event.target.value);
  };

  const handleChange2 = (event) => {
    const inputValue = event.target.value;
    setMtAccount(inputValue);
    setError(inputValue.trim() === "");
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange3 = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { gilad, jason, antoine } = state;
  const error1 = [gilad, jason, antoine].filter((v) => v).length !== 2;

  const useStyles = styled((theme) => ({
    customContainer: {
      [theme.breakpoints.up("xs")]: {
        maxWidth: "100%",
        padding: 0,
        margin: "0",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "100%",
        padding: 0,
        margin: "0",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "100%",
        padding: 0,
        margin: "0",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "100%",
        padding: 0,
        margin: "0",
      },
    },
  }));
  return (
    <>
      <div className="join-parent">
        <Container
          className="main-container"
          component="main"
          style={{ width: "100%", padding: 0, margin: "0 0" }}
        >
          <Box
            sx={{
              boxShadow: 2,
              borderRadius: 1,
              px: 2,
              py: 1,
              marginTop: 1,
              marginBottom: "1.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <p
              class="summery"
              style={{
                display: "flex",
                margin: 0,
                opacity: 0.9,
                width: "100%",
              }}
            >
              Royal Forex
            </p>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                opacity: "0.7",
                fontWeight: "100",
              }}
            >
              <span
                className=""
                style={{
                  color: "#000000dd",
                  fontWeight: "100",
                  fontSize: "14px",
                }}
              >
                Trading Interval
              </span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      onClick={handleTooltipOpen}
                      onMouseClick={() => setOpen(!open)}
                      // onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              Daily
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                opacity: "0.6",
              }}
            >
              Min. Deposit{" "}
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                              opacity: "0.6",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      onClick={handleTooltipOpen}
                      onMouseEnter={() => setOpen(true)}
                      onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              $50.00
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                fontWeight: "100",
                opacity: "0.6",
              }}
            >
              Min. Withdrawal
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      onClick={handleTooltipOpen}
                      onMouseEnter={() => setOpen(true)}
                      onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span>$50.00</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Min. Initial Investment</span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <LightTooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            Trading Interval
                          </p>
                        </div>
                      </React.Fragment>
                    }
                  >
                    {/* <span
                      onClick={handleTooltipOpen}
                      onMouseEnter={() => setOpen(true)}
                      onMouseLeave={() => setOpen(false)}
                      style={{
                        backgroundColor: open ? "#dbdbdb" : "#f0f0f0", // Change color based on hover state
                        color: "#8f8f8f",
                        padding: "1px 6px 1px 6px",
                        borderRadius: "2px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        fontSize: "12px",
                      }}
                    >
                      i
                    </span> */}
                  </LightTooltip>
                </div>
              </ClickAwayListener>
              <div
                style={{
                  flex: 1,
                  marginTop: "1px", // Adjust as needed
                  marginBottom: "1px", // Adjust as needed
                  borderBottom: "1px dotted #ddd",
                  backgroundClip: "padding-box", // Ensures the dots are round,
                  opacity: "0.6",
                  fontWeight: "100",
                }}
              />
              <span>$50.00</span>
            </div>

            <Divider
              color="black"
              sx={{
                marginTop: "1.5rem",
                marginBottom: "0.5rem",
                width: "100%",
                borderColor: "#cccccc", // Optional: Set the color of the dashed line
                // borderWidth: '1px', // Optional: Set the width of the dashed line
              }}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Performance Fees </span>
              <span>$0.00</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Equity</span>
              <span>$0.00</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: 0,
                opacity: 0.9,
                fontSize: "14px",
                gap: "10px",
                height: "22px",
                justifyContent: "space-between",
                opacity: "0.6",
                fontWeight: "100",
              }}
            >
              <span>Performance fee (%)</span>
              <span>100%</span>
            </div>
          </Box>
        </Container>
      </div>
    </>
  );
}
