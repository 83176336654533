import { Modal as BaseModal } from "@mui/base/Modal";
import { AttachMoney } from "@mui/icons-material";
import { Button, Divider, InputAdornment, TextField } from "@mui/material";
import { css, styled } from "@mui/system";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import toasterConfig from "../Config/toasterConfig";

export default function WithdrawModal({ open, setOpen }) {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [accountNumber, setAccountNumber] = React.useState(0);
  const [amount, setAmount] = React.useState("");
  const [finalAmount, setFinalAmount] = React.useState('');
  const [helperText, setHelperText] = React.useState('min $50.00');
  const [error, setError] = React.useState(false);
  const [otpModal, setOtpModal] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [otpError, setOtpError] = React.useState(false);
  const [resendTimer, setResendTimer] = React.useState(120); // 2 minutes in seconds
  const [resendDisabled, setResendDisabled] = React.useState(false);

  React.useEffect(() => {
    // Assuming API_URL is defined somewhere in your code

    axios
      .post(API_URL, {
        mood: "getAccountDetails",
        userId: userData.id,
      })
      .then((response) => {
        console.log(response.data);
        setTotalAmount(response.data.data.totalAmount);
        setAccountNumber(response.data.data.account_number);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const username = userData?.username;
  const handleOpen = () => {
    if (open === "withdrawal") return true;
    else {
      return false;
    }
  };
  const handleClose = () => {
    setOpen("");
  };
  const handleOtpChange = (event) => {
    const otpValue = event.target.value;
    setOtp(otpValue);
    setOtpError(otpValue.trim() === "");
  };

  // const handleChange2 = (event) => {
  //   const inputValue = event.target.value;
  //   setAmount(inputValue);
  //   setError(inputValue.trim() === "");
  // };

  const handleChange2 = (event) => {
    const value = event.target.value;
    setAmount(value);

     if (value < 50) {
      setError(true);
      setHelperText('Amount must be at least $50.00');
    } else {
      setError(false);
      const fee = value * 0.1;
      const finalAmount = value - fee;
         // Set the calculated final amount
      setFinalAmount(finalAmount);

      // Set error if the amount is below the minimum threshold
     
      setHelperText(`10% fee: $${fee.toFixed(2)}, Final amount: $${finalAmount.toFixed(2)}`);
    }
  };

  const handleWithdrawal = () => {
    if (accountNumber == null) {
      toasterConfig.error("Update wallet in the profile page");
      return false;
    }

    // Check if the withdrawal amount is available in the wallet
    if (parseFloat(amount) <= totalAmount && parseFloat(amount) >= 50) {
      // If available, show the OTP input field
      sendOtpHandler();
    } else {
      let message = "";
      if (parseFloat(amount) < 50) {
        message = "Minimum withdrawal amount is $50";
      } else {
        message =
          "Withdrawal amount exceeds available balance. Please retry with the available funds.";
      }
      toasterConfig.error(message);
    }
  };

  const sendOtpHandler = async () => {
    if (accountNumber == null || accountNumber === "") {
      toasterConfig.error("Update wallet in the profile page");
      return false;
    }
    try {
      const response = await axios.post(API_URL, {
        mood: "otpGenerate",
        userId: userData.id,
      });

      toasterConfig.success(response.data.message);
      setOtpModal("withdrawalWithOTP");
      setResendTimer(120);
      setResendDisabled(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOtpSubmit = () => {
    axios
      .post(API_URL, {
        mood: "otpVerify",
        userId: userData.id,
        otp: otp,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
          // If OTP is correct, proceed with withdrawal
          handleWithdrawalSubmit();
        } else {
          toasterConfig.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleWithdrawalSubmit = () => {
    axios
      .post(API_URL, {
        mood: "withdrawAmount",
        userId: userData.id,
        server: userData?.server,
        email: userData?.email,
        username: userData.username,
        amount: parseFloat(amount),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
          // If OTP is correct, proceed with withdrawal
          toasterConfig.success("Withdrawal successful!");
          setOpen(""); // Close the modal after handling withdrawal
          navigate("/app/history?query=request");
        } else {
          setOpen("");
          toasterConfig.error(response.data.message);
        }
      })
      .catch((error) => {
        setOpen("");
        toasterConfig.error(error.response.data.message); // Use 'error.response.data.message'
        console.error("Error fetching data:", error);
      });
  };
  React.useEffect(() => {
    let interval;
    if (resendTimer > 0 && resendDisabled) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (resendTimer === 0) {
      setResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [resendTimer, resendDisabled]);

  const handleResendOTP = () => {
    sendOtpHandler();
  };

  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={handleOpen()}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: 400 }}>
          <h4
            id="unstyled-modal-title"
            className="modal-title"
            style={{ fontWeight: "600" }}
          >
            Withdraw
          </h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p
                style={{ fontSize: "10px" }}
                id="unstyled-modal-description"
                className="modal-description"
              >
                Money Manager #066
              </p>
              <h6
                id="unstyled-modal-title"
                className="modal-title"
                style={{ fontWeight: "300", fontSize: "14px" }}
              >
                Royal Forex
              </h6>
            </div>

            <div>
              <p
                style={{ fontSize: "10px" }}
                id="unstyled-modal-description"
                className="modal-description"
              >
                Investment #{userData?.username}
              </p>
              <h6
                id="unstyled-modal-title"
                className="modal-title"
                style={{ fontWeight: "300", fontSize: "14px" }}
              >
                {username}
              </h6>
            </div>
          </div>

          <div>
            <p
              style={{ fontSize: "10px" }}
              id="unstyled-modal-description"
              className="modal-description"
            >
              Funds (Investment)
            </p>
            <h6
              id="unstyled-modal-title"
              className="modal-title"
              style={{ fontWeight: "300", fontSize: "14px" }}
            >
              ${totalAmount}
            </h6>
          </div>
          <Divider />

          <div>
            {otpModal === "withdrawalWithOTP" ? (
              <>
                <TextField
                  margin="normal"
                  required
                  error={otpError}
                  fullWidth
                  value={otp}
                  onChange={handleOtpChange}
                  id="otp"
                  label="OTP"
                  name="otp"
                  type="text"
                  autoFocus
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleOtpSubmit}
                    style={{
                      textTransform: "capitalize",
                      color: "white",
                      border: "1px solid  #E6E6E6",
                      background: "#37474F",
                      marginRight: "10px",
                    }}
                    variant="outlined"
                  >
                    Submit OTP
                  </Button>

                  <div>
                    <Button
                      onClick={handleResendOTP}
                      style={{
                        textTransform: "capitalize",
                        color: resendDisabled ? "black" : "white",
                        border: "1px solid  #E6E6E6",
                        background: resendDisabled ? "transparent" : "#37474F",
                      }}
                      variant="outlined"
                      disabled={resendDisabled}
                    >
                      Resend OTP
                    </Button>
                  </div>
                </div>
                <>
                  {resendDisabled && (
                    <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                      Resend in {Math.floor(resendTimer / 60)}:
                      {resendTimer % 60 < 10
                        ? `0${resendTimer % 60}`
                        : resendTimer % 60}{" "}
                      seconds
                    </span>
                  )}
                </>
              </>
            ) : (
              <>
                <TextField
                  className="helper-text"
                  margin="normal"
                  required
                  error={error}
                  fullWidth
                  onChange={handleChange2}
                  id="amount"
                  label="Amount"
                  name="amount"
                  type="number"
                  autoFocus
                  fontS
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <AttachMoney />
                      </InputAdornment>
                    ),
                  }}
                  
                  helperText={helperText}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleWithdrawal}
                    style={{
                      textTransform: "capitalize",
                      color: "white",
                      border: "1px solid  #E6E6E6",
                      background: "#37474F",
                      marginRight: "10px",
                    }}
                    variant="outlined"
                  >
                    <AttachMoney />
                    Withdraw
                  </Button>

                  <Button
                    style={{
                      textTransform: "capitalize",
                      color: "black",
                      border: "1px solid  #E6E6E6",
                    }}
                    onClick={handleClose}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    max-width: 260px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 600;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[900]};
      margin-bottom: 4px;
    }
    & #amount-helper-text {
      font-size: 12px;
      font-weight: 600;
    }
  `
);
