import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  CircularProgress,
  InputAdornment,
  TextField
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Language from "./Languages";

export default function LogIn() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [btncolor, setBtnColor] = useState("#e0e0e0");
  const [hover, setHover] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}`, {
        username,
        password,
        mood: "login",
      });
      setLoading(false);
      if (response.data.success) {
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem("userData", JSON.stringify(response.data.data));
        sessionStorage.setItem("token", response.data.token);
        navigate("/app/investments");
      } else {
        showErrorToast(response.data.message);
      }
    } catch (error) {
      showErrorToast("Error logging in. Please try again.");
    }
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      style: { backgroundColor: "#bd362f" },
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "0.7rem 2rem" }}>
        {/* <Language /> */}
      </div>
      <Container component="main">
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src="/assets/taurex_logo.png" alt="logo" style={{ width: "170px", marginTop: "2rem" }} />
        </div>
        <p className="login-heading" style={{ marginTop: "3rem", textAlign: "center" }}>
          <span style={{ font: "400 24px/32px Nunito,sans-serif", color: "rgb(0 0 0 / 87%)", fontWeight: "600", fontSize: "22px" }}>
            Taurex - PAMM
          </span>
          <span style={{ opacity: ".5", paddingLeft: "10px", color: "#6c757d", fontWeight: "700" }}>
            Investor
          </span>
        </p>

        <Box className="login-box">
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={(e) => setUsername(e.target.value)}
              label="MT5 Account"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle style={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              type="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <LockIcon style={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: btncolor, height: "52px" }}
              sx={{ mt: 3 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                <ChevronRightIcon style={{ color: "white", fontSize: "40px" }} />
              )}
            </Button>
          </Box>
        </Box>
        <p
          onClick={() => navigate("/app/auth/register/investor")}
          style={{ cursor: "pointer", marginTop: "1rem", textAlign: "center", fontSize: "small", opacity: "0.8" }}
        >
          Register an investor account
        </p>
        <p
          onClick={() => navigate("/app/auth/investor/forgot-password")}
          style={{ cursor: "pointer", marginTop: "1rem", textAlign: "center", fontSize: "small", opacity: "0.8" }}
        >
          Forgot Password?
        </p>
        <footer style={{ marginTop: "2rem", textAlign: "center", fontSize: "small", color: "#6c757d", padding: "1rem" }}>
          Copyright © {currentYear}
          <a
            href="https://www.tradetaurex.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: hover ? "#007bff" : "#6c757d", textDecoration: "none", transition: "color 0.3s" }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            Taurex
          </a>
          . All rights reserved.
        </footer>
      </Container>
      <ToastContainer />
    </>
  );
}
