import { ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Requests from "./Requests";
import Transactions from "./Transactions";

export default function HistoryDetails() {
  // const navigate = useNavigate();
  const [activeButton, setActiveButton] = React.useState("transactions");

  // const handleLogin = () => {
  //   navigate("/home");
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get("email"),
  //     password: data.get("password"),
  //   });
  // };

  // const [server, setServer] = React.useState("Vantage International Live 11");
  // const [mtAccount, setMtAccount] = React.useState("");
  // const [error, setError] = useState(false);

  // const handleChange = (event) => {
  //   setServer(event.target.value);
  // };

  // const handleChange2 = (event) => {
  //   const inputValue = event.target.value;
  //   setMtAccount(inputValue);
  //   setError(inputValue.trim() === "");
  // };

  // const [open, setOpen] = React.useState(false);

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   setOpen(true);
  // };

  // const LightTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "rgba(0, 0, 0, 0.87)",
  //     boxShadow: theme.shadows[1],
  //     fontSize: 11,
  //   },
  // }));

  // const [state, setState] = React.useState({
  //   gilad: true,
  //   jason: false,
  //   antoine: false,
  // });

  // const handleChange3 = (event) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  // const { gilad, jason, antoine } = state;
  // const error1 = [gilad, jason, antoine].filter((v) => v).length !== 2;

  // const useStyles = styled((theme) => ({
  //   customContainer: {
  //     [theme.breakpoints.up("xs")]: {
  //       maxWidth: "100%",
  //       padding: 0,
  //       margin: "0",
  //     },
  //     [theme.breakpoints.up("sm")]: {
  //       maxWidth: "100%",
  //       padding: 0,
  //       margin: "0",
  //     },
  //     [theme.breakpoints.up("md")]: {
  //       maxWidth: "100%",
  //       padding: 0,
  //       margin: "0",
  //     },
  //     [theme.breakpoints.up("lg")]: {
  //       maxWidth: "100%",
  //       padding: 0,
  //       margin: "0",
  //     },
  //   },
  // }));
  return (
    <>
      <div className="join-parent">
        <Container
          className="main-container"
          component="main"
          style={{ width: "100%", padding: 0, margin: "0 0" }}
        >
          {/* <Box
                sx={{
                    boxShadow: 2,
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    marginTop: 1,
                    marginBottom: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgr  oundColor: "white",
                }}
            >
                <p style={{
                    display: "flex",
                    margin: 0,
                    opacity: 0.9,
                    width: "100%",
                    fontSize:"24px",
                    fontWeight:"500",
                    color:"rgba(0,0,0,0.87)",

                }}>
                    Royal Forex PAMM Trading A/c
                </p>
                <p style={{
                    display: "flex",
                    margin: 0,
                    width: "100%",
                    marginBottom: "5px",
                    fontSize: "13px",
                    marginTop: "-4px",
                    opacity:"0.4",
                }}
                >
                    Money Manager #1066
                </p>
                <p style={{
                    display: "flex",
                    margin: 0,
                    opacity: 0.9,
                    width: "100%",
                    marginTop: "1rem",
                    fontSize: "14px",
                    opacity:"0.6",
                    fontWeight:"100"
                }}>
                    Most Secure Forex Fund Management company
                </p>
            </Box> */}

          {/* <Box
                sx={{
                    boxShadow: 2,
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    marginTop: 1,
                    marginBottom: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                }}
            > */}
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              style={{
                padding: "14px 12px",
                color: activeButton === "transactions" ? "black" : "black",
                backgroundColor:
                  activeButton === "transactions" ? "#D7D7D7" : "white",
                borderColor: "#D7D7D7",
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: "bold",
                opacity: "0.8",
              }}
              onClick={() => setActiveButton("transactions")}
            >
              Transactions
            </Button>
            <Button
              style={{
                padding: "14px 12px",
                color: activeButton === "requests" ? "black" : "black",
                backgroundColor:
                  activeButton === "requests" ? "#D7D7D7" : "transparent",
                borderColor: "#D7D7D7",
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: "bold",
                opacity: "0.8",
              }}
              onClick={() => setActiveButton("requests")}
            >
              Requests
            </Button>
          </ButtonGroup>

          {activeButton === "transactions" && <Transactions />}
          {activeButton === "requests" && <Requests />}

          {/* </Box> */}
        </Container>
      </div>
    </>
  );
}
