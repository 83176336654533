import AccountCircle from "@mui/icons-material/AccountCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LogIn() {
  const API_URL = `${process.env.REACT_APP_API_URL}/wm_user.php`;

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const [username, setUsernames] = useState("");
  const [password, setPasswords] = useState("");
  const [btncolor, btnColor] = useState("#e0e0e0");

  const setUsername = (e) => {
    setUsernames(e);
    if (e !== "" && password !== "") {
      setDisabled(false);
      btnColor("#37474f");
    } else {
      btnColor("#e0e0e0");
    }
  };

  const setPassword = (e) => {
    setPasswords(e);

    if (e !== "" && username !== "") {
      btnColor("#37474f");
      setDisabled(false);
    } else {
      btnColor("#e0e0e0");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}`, {
        username,
        password,
        mood: "login",
      });

      if (response.data.success === true) {
        // alert("Login Successful");
        sessionStorage.setItem("isAdmin", true);
        sessionStorage.setItem("userData", JSON.stringify(response.data.data));
        setTimeout(() => {
          navigate("/app/admin/dashboard");
        }, 1000);
      } else {
        toast.error(
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
                margin: "0 0 12px 0",
              }}
            >
              {response.data.message}
            </p>
            <span
              style={{
                fontSize: "14px",
                display: "block",
                lineHeight: "0",
                marginBottom: "5px",
              }}
            >
              Invalid login/password
            </span>
          </div>,

          {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
            style: {
              backgroundColor: "#bd362f", // Set your desired background color here
            },
          }
        );
      }
      setDisabled(false);
      setLoading(false);
    } catch (error) {
      setDisabled(false);
      setLoading(false);
      toast.error(error, {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
        style: {
          backgroundColor: "#bd362f", // Set your desired background color here
        },
      });
      console.error("Error signing up:", error);
    }
  };

  const [error, setError] = useState(false);

  return (
    <>
      <head>
        <title>Login Page</title>
      </head>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "0.7rem 2rem",
        }}
      ></div>
      <Container component="main">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <img src="/logo.png" alt="logo" style={{ width: "300px", height: "50px", marginTop: "2rem" }} /> */}
          <img
            src="/assets/taurex_logo.png"
            alt="logo"
            style={{ width: "170px", height: "auto", marginTop: "2rem" }}
          />
        </div>
        <p
          className="login-heading"
          style={{
            marginTop: "3rem",
            padding: "0 5px",
            marginBottom: "8px",
            textalign: "center",
          }}
        >
          <span
            style={{
              font: "400 24px/32px Nunito,sans-serif",
              color: "rgb(0 0 0 / 87%)",
              fontWeight: "600",
              fontsize: "22px",
            }}
          >
            Taurex - PAMM
          </span>
          <span
            style={{
              opacity: ".5",
              paddingLeft: "10px",
              color: "#6c757d",
              fontWeight: "700",
            }}
          >
            Admin
          </span>
        </p>

        <Box className="login-box">
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              error={error}
              fullWidth
              onChange={(e) => setUsername(e.target.value)}
              // onChange={handleChange2}
              id="username"
              label="User name"
              name="username"
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle style={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="normal"
              required
              error={error}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              // onChange={handleChange2}
              name="password"
              label="Password"
              type="password"
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <LockIcon style={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ "background-color": btncolor, height: "52px" }}
              sx={{ mt: 3 }}
              disabled={disabled}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                <>
                  <ChevronRightIcon
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontWeight: "400",
                    }}
                  />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
}
