import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import AccountDetails from "./components/AccountDetails";
import History from "./components/History";
import Home from "./components/Home";

import AppAuth from "./components/appAuth";
import BlankPage from "./components/BlankPage";
import InvestmentDetails2 from "./components/InvestmentDetails2";
import Investments from "./components/Investments";
import Language from "./components/Languages";
import LogIn from "./components/LogIn";
import ForgotPassword from "./components/ForgotPassword";
import LogInManager from "./components/LogInManager";
import NotFound from "./components/NotFound";
import OldJoin from "./components/OldJoin";
import Payment from "./components/Payment";
import RegisterInvestor from "./components/RegisterInvestor";
import ManagerRegister from "./components/RegisterManager";
import RequestPage from "./components/RequestPage";
import Support from "./components/Support";
// Admin components go here
import AdminSymbols from "./components/Admin/AdminSymbols";
import AdminDemoPositions from "./components/Admin/DemoPosition";
import AdminTransactionHistory from "./components/Admin/History";
import AdminInvestorRegistration from "./components/Admin/InvestorSettings";
import AdminLogin from "./components/Admin/LogIn";
import AdminPerformanceFee from "./components/Admin/PerformanceFee";
import AdminPerformanceFeeView from "./components/Admin/PerformanceFeeView";
import AdminPositions from "./components/Admin/Positions";
import AdminSettings from "./components/Admin/Setting";
import SupportTicket from "./components/Admin/SupportTicket";
import AdminUserHistory from "./components/Admin/UserHistory";
import AdminUsers from "./components/Admin/Users";

const PrivateRoute = ({ element, adminOnly }) => {
  const loggedIn = sessionStorage.getItem("loggedIn");
  const isAdmin = sessionStorage.getItem("isAdmin");

  if (loggedIn) {
    // Check for admin privileges if adminOnly prop is true
    if (adminOnly && isAdmin !== "true") {
      // Redirect to the login page if not authenticated or not an admin
      return <Navigate to="/app/auth" />;
    }

    return element;
  } else {
    // Redirect to the login page if not authenticated
    return <Navigate to="/app/auth" />;
  }
};

const AdminRoute = ({ element }) => {
  const isAdmin = sessionStorage.getItem("isAdmin");

  if (isAdmin) {
    return element;
  } else {
    // Redirect to the login page or another appropriate location
    return <Navigate to="/app/auth" />;
  }
};

function App() {
  return (
    <>
      <style jsx global>{`
        body {
          background-color: #fafafa;
          font: 400 24px/32px Nunito, sans-serif;
        }
      `}</style>
      <div className="App">
        <Router>
          <Routes>
            {/* Add a catch-all route for 404 */}
            <Route path="*" element={<NotFound />} />
            {/* Set the default route to /app/auth */}
            <Route path="/" element={<Navigate to="/app/auth" replace />} />

            <Route path="/app/auth/investor" element={<LogIn />} />
            <Route path="/app/auth/investor/forgot-password" element={<ForgotPassword />} />
            <Route path="/app/auth/manager" element={<LogInManager />} />
            <Route path="/app/auth" element={<AppAuth />} />
            {/* <Route path="/app/auth/register/investor" element={<OldJoin />} /> */}
            {/* <Route path="/app/auth/register/investor" element={<BlankPage />} /> */}
            <Route
              path="/app/auth/register/investor"
              element={<OldJoin />}
            />
            <Route
              path="/app/auth/register/manager"
              element={<ManagerRegister />}
            />
            <Route path="/home" element={<PrivateRoute element={<Home />} />} />
            <Route
              path="/app/investments"
              element={<PrivateRoute element={<Investments />} />}
            />
            <Route
              path="/app/history"
              element={<PrivateRoute element={<History />} />}
            />
            <Route
              path="/app/payment"
              element={<PrivateRoute element={<Payment />} />}
            />

            <Route
              path="/app/support"
              element={<PrivateRoute element={<Support />} />}
            />

            <Route path="/languages" element={<Language />} />
            <Route
              path="/registerinvestor"
              element={<PrivateRoute element={<RegisterInvestor />} />}
            />

            <Route
              path="/app/my-account"
              element={<PrivateRoute element={<AccountDetails />} />}
            />
            <Route
              path="/app/investments/:paramValue"
              element={<PrivateRoute element={<InvestmentDetails2 />} />}
            />
            <Route
              path="api/investmentReports/dl/7990"
              element={<PrivateRoute element={<RequestPage />} />}
            />

            {/* Example of an admin route */}
            <Route path="/app/admin" element={<AdminLogin />} />

            <Route
              path="/app/admin/dashboard"
              element={<AdminRoute element={<AdminTransactionHistory />} />}
            />
            <Route
              path="/app/admin/users"
              element={<AdminRoute element={<AdminUsers />} />}
            />
            <Route
              path="/app/admin/users/:paramValue"
              element={<AdminRoute element={<AdminUserHistory />} />}
            />
            <Route
              path="/app/admin/symbols"
              element={<AdminRoute element={<AdminSymbols />} />}
            />
            <Route
              path="/app/admin/positions"
              element={<AdminRoute element={<AdminPositions />} />}
            />
            <Route
              path="/app/admin/demo-positions"
              element={<AdminRoute element={<AdminDemoPositions />} />}
            />
            <Route
              path="/app/admin/performance-fees"
              element={<AdminRoute element={<AdminPerformanceFee />} />}
            />
            <Route
              path="/app/admin/performance-fees/:paramValue"
              element={<AdminRoute element={<AdminPerformanceFeeView />} />}
            />
            <Route
              path="/app/admin/investor-registration"
              element={<AdminRoute element={<AdminInvestorRegistration />} />}
            />
            <Route
              path="/app/admin/settings"
              element={<AdminRoute element={<AdminSettings />} />}
            />
            <Route
              path="/app/admin/support"
              element={<AdminRoute element={<SupportTicket />} />}
            />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
