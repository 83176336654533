import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, CircularProgress, Box, Container } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const API_URL = `${process.env.REACT_APP_API_URL}/api.php`;
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Check if email is valid
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    try {
      // Send request to server to verify email and send reset instructions
      const response = await axios.post(`${API_URL}`, {
        email,
        mood: "forgot_password",
      });

      setLoading(false);
      if (response.data.success) {
        toast.success("Password reset link sent to your email.", {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
        });
      } else {
        toast.error(response.data.message || "Email does not exist.", {
          position: "bottom-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error sending reset link. Please try again later.", {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
      });
    }
  };

  // Handle back button click
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2>Forgot Password</h2>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              error={!!emailError}
              helperText={emailError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Send Reset Link"}
            </Button>
            {/* Back Button */}
            <Button
              fullWidth
              variant="outlined"
              onClick={handleBack}
              sx={{ mb: 2 }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
}
