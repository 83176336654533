import { Divider, LinearProgress, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { ArrowBack } from "@mui/icons-material";
import Grid from "@mui/material/Grid"; // Grid version 1
import { useNavigate } from "react-router-dom";
import Language from "./Languages.js";

export default function RegisterInvestor() {
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const backToHandler = () => {
    userData.id ? navigate("/app/investments") : navigate("/");
  };

  // const rows = [
  //   createData(
  //     "OFX ",
  //     "$1.10",
  //     "$64.10",
  //     "$98.67",
  //     "$0.00",
  //     "Dec 10, 2023 – Dec 16, 2023",
  //     "Jul 28, 2023"
  //   ),
  // ];

  return (
    <>
      {/* <ResponsiveAppBar /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0.7rem 2rem",
        }}
      >
        <Button
          style={{
            textTransform: "capitalize",
            color: "black",
            border: "1px solid  #E6E6E6",
            fontWeight: "600",
          }}
          variant="outlined"
          onClick={backToHandler}
        >
          <ArrowBack fontSize="small" style={{ marginRight: "5px" }} />
          Back to Sign in
        </Button>
        {/* <Language /> */}
      </div>
      <div className="dashboard">
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <img
            src="/assets/taurex_logo.png"
            alt="logo"
            style={{ width: "200px", height: "auto", marginTop: "2rem" }}
          />
        </div>
        <Typography
          style={{
            paddingLeft: "0",
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "lighter",
            // opacity: "0.8",
          }}
          variant="h6"
        >
          PAMM - Royal Forex
          <span
            style={{
              marginLeft: "10px",
              fontSize: "24px",
              color: "#A9A9A9",
              fontWeight: "lighter",
            }}
          >
            Investor Registration
          </span>
        </Typography>

        <Grid item xs={5} style={{ paddingLeft: "95px", marginBottom: "30px" }}>
          <TextField id="standard-basic" label="Search" variant="standard" />
        </Grid>
      </div>

      <div className="table" style={{ maxWidth: "88%", margin: "auto" }}>
        <TableContainer component={Paper}>
          {isLoading && <LinearProgress />}

          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {!isLoading && (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "12px", fontWeight: "600" }}>
                      Manager
                    </TableCell>
                    {/* <TableCell  style={{fontSize:"12px",opacity:"0.6"}} align="right">Funds</TableCell>
                <TableCell style={{fontSize:"12px",opacity:"0.6"}} align="right">Net Profit</TableCell>
                <TableCell style={{fontSize:"12px",opacity:"0.6"}} align="right">Trade results</TableCell>
                <TableCell style={{fontSize:"12px",opacity:"0.6"}} align="right">Trade results</TableCell>
                <TableCell style={{fontSize:"12px",opacity:"0.6"}} align="left">Trading interval</TableCell>
                <TableCell style={{fontSize:"12px",opacity:"0.6"}} align="right">Created</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {rows.map((row) => (
                <TableRow
                  key={row.pamm}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.pamm}
                  </TableCell>
                  <TableCell align="right">{row.funds}</TableCell>
                  <TableCell style={{color:"#5C9B6A"}} align="right">{row.netProfit}</TableCell>
                  <TableCell style={{color:"#5C9B6A"}} align="right">{row.tradeResults}</TableCell>
                  <TableCell  style={{color:"#5C9B6A"}} align="right">{row.tradeResults2}</TableCell>
                  <TableCell align="left">{row.tradingInterval}</TableCell>
                  <TableCell align="right">{row.created}</TableCell>
                  <TableCell align="right">{row.closed}</TableCell>
                </TableRow>
                
              ))} */}
                  <Divider
                    style={{
                      width: "1%",
                      margin: "10px 0.3rem",
                      visibility: "hidden",
                    }}
                  />
                </TableBody>
              </>
            )}
          </Table>
          {/* <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{fontSize:"10px"}}
      /> */}
        </TableContainer>
      </div>
    </>
  );
}
